<template>
  <div>
    <navigation />
    <shares />
  </div>
</template>
<script>
export default {
  components: {
    navigation () { return import('@/components/frontend/navigation/navigation') },
    shares () { return import('@/components/signIn/ressources') }
  },
  props: {},
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {
    if (this.$route.params.passkey) {
      var email = atob(atob(atob(this.$route.params.passkey)))
      this.$store.commit('setShareEmail', email)
      this.$router.push({
        name: 'sharesCalendar',
        params: {
          ressource: 0,
          calendar: 0
        }
      })
    }
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
